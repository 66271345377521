<template>
  <div class="careers">
    <div class="banner">
      <img src="/assets/img/banner-5.jpg" alt="" class="banner">

      <div class="banner-text">
        <h2>{{ $t('navbar.careers') }}</h2>
      </div>
    </div>

    <div class="container is-max-widescreen my-6">

      <div class="careers-header">
        <h3>{{ $t('careers.title') }}</h3>
        <p v-html="$t('careers.desc')"></p>
      </div>

      <div class="columns">
        <template v-if="!isLoading">
          <div class="column is-half-tablet" v-for="career in careers" :key="career.career_id">
            <div class="card card-career">
              <div class="card-content">
                <h4>{{ career.title }}</h4>
                <b-button type="is-primary" tag="router-link" :to="`/careers/${career.slug}`">Read more</b-button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="column is-half-tablet" v-for="n in 4" :key="n">
            <div class="card card-career">
              <div class="card-content">
                <b-skeleton width="40%" class="mb-4" animated></b-skeleton>
                <b-skeleton width="20%" animated></b-skeleton>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.careers {
  .banner {
    position: relative;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-text {
      position: relative;
      z-index: 2;
      color: #FFFFFF;
      text-transform: capitalize;
    }

    h2 {
      font-size: 3rem;
      font-weight: bold;
    }

    img {
      position: absolute;
      vertical-align: middle;
      width: 100%;
      object-fit: cover;
      filter: brightness(.65);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .careers-header {
    color: #000000;

    h3 {
      font-size: 1.4rem;
      font-weight: 600;
    }

    margin-bottom: 1rem;
  }

  .card-career {
    h4 {
      font-size: 1.2rem;
      margin-bottom: .725rem;
      font-weight: bold;
    }
  }
}
</style>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: 'Careers',
  data: () => {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState([
      'careers',
    ]),
  },
  created() {
    this.isLoading = true
    this.getCareers().finally(() => {
      this.isLoading = false
    })
  },
  methods: {
    ...mapActions([
      'getCareers',
    ]),
  },
}
</script>
